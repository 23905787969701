<template>
  <div>
    <b-row class="match-height">

      <b-col
        lg="3"
        order-lg="2"
      >
        <b-card no-body>
          <div
            v-for="location in locations"
            :key="location.vehicle.id"
            class="border-bottom px-1 py-50"
            :class="{'bg-light-primary': highlightLocations.includes( location.vehicle.id)}"
            @mouseover="highlightMarker([location.vehicle.id])"
            @mouseout="removeHighlight"
          >
            <b-row>
              <b-col>
                <b-badge
                  variant="light-secondary cursor-pointer"
                  @click="centerMapOnMarker(location)"
                >
                  {{ location.vehicle.sideNumber }}
                  <span class="font-small-2">({{ location.vehicle.registrationNumber }})</span>
                </b-badge>
              </b-col>
              <b-col
                v-if="location.team"
                cols="4"
                class="text-right"
              >
                <b-badge :variant="location.team.status?'success' :'danger'">
                  {{ location.team.teamName.name }}
                </b-badge>
              </b-col>
            </b-row>
            <b-row
              v-if="location.team && location.team.currentOrder"
              class="mt-50"
            >
              <b-col
                cols="7"
                class="font-small-2 text-truncate pr-0"
              >
                {{ $t('order.status.' + location.team.currentOrder.status) }}
              </b-col>
              <b-col
                cols="5"
                class="font-small-2 text-right  text-truncate pl-25"
              >
                <router-link :to="{ name: 'order-view', params: { id: location.team.currentOrder.id } }">
                  {{ location.team.currentOrder.fullNumber }}
                </router-link>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col class="">
                <!--                <b-avatar-->
                <!--                  v-b-tooltip.hover.top="$t('dashboard.map.priority-driving-mode')"-->
                <!--                  class="mx-25"-->
                <!--                  :variant="location.locationStatus.priorityDrivingMode ? 'primary': 'light-secondary'"-->
                <!--                  size="24"-->
                <!--                >-->
                <!--                  <feather-icon icon="LoaderIcon" />-->
                <!--                </b-avatar>-->
                <b-avatar
                  v-b-tooltip.hover.top="$t('dashboard.map.engine-on')"
                  class="mx-25"
                  :variant="location.locationStatus.engineOn ? 'success': 'light-secondary'"
                  size="24"
                >
                  <feather-icon icon="PowerIcon" />
                </b-avatar>
                <b-avatar
                  v-if="location.locationStatus.accidentDetected"
                  v-b-tooltip.hover.top="$t('dashboard.map.accident-detected')"
                  class="mx-25"
                  :variant="location.locationStatus.accidentDetected ? 'danger': 'light-secondary'"
                  size="24"
                >
                  <feather-icon icon="AlertOctagonIcon" />
                </b-avatar>
              </b-col>
              <b-col
                cols="4"
                class="text-right"
              >
                <span class="font-small-3 text-nowrap">
                  {{ location.locationStatus.speed }}&nbsp;km/h
                </span>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>

      <b-col
        lg="9"
        order-lg="1"
      >
        <b-card no-body>
          <rc-overlay :show="loading">
            <!-- map -->
            <l-map
              ref="map"
              :zoom.sync="zoom"
              :options="mapOptions"
              :center="center"
              :bounds="bounds"
              :min-zoom="minZoom"
              :max-zoom="maxZoom"
              style="height: 700px; width: 100%"
              class="rounded"
            >
              <l-control-layers
                v-if="false"
                :position="layersPosition"
                :collapsed="false"
                :sort-layers="true"
              />
              <l-tile-layer
                v-for="tileProvider in tileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                :attribution="tileProvider.attribution"
                :token="null"
                layer-type="base"
              />
              <l-control-zoom :position="zoomPosition" />
              <l-control-attribution
                :position="attributionPosition"
                :prefix="attributionPrefix"
              />
              <l-control-scale :imperial="imperial" />

              <v-marker-cluster>
                <l-marker
                  v-for="marker in markers"
                  :key="marker.id"
                  :visible="marker.visible"
                  :draggable="marker.draggable"
                  :lat-lng.sync="marker.position"
                  :icon-anchor="[14, 14]"
                  :icon-size="[28,28]"
                  @mouseover="highlightListItem([marker.id])"
                  @mouseout="removeHighlight"
                >
                  <l-icon>
                    <div
                      class="marker-icon"
                      :class="{'highlight': highlightMarkers.includes(marker.id)}"
                    >
                      <div
                        v-if="marker.sideNumber"
                        class="side-number font-weight-bolder"
                      >
                        {{ marker.sideNumber }}
                      </div>
                      <feather-icon
                        v-else
                        icon="TruckIcon"
                        size="16"
                      />
                    </div>
                  </l-icon>

                  <l-popup :content="marker.tooltip" />
                  <!--                  <l-tooltip :content="marker.tooltip" />-->
                </l-marker>
              </v-marker-cluster>
            </l-map>
          </rc-overlay>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import {
  BRow, BCol, BCard, BBadge, BAvatar, VBTooltip,
} from 'bootstrap-vue'
import {
  LMap,
  LTileLayer,
  LMarker,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers,
  LIcon,
} from 'vue2-leaflet'
import { latLngBounds, Icon } from 'leaflet'
import guard from '@/guard'
import { empty } from '@/helpers/helpers'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: true,
    attribution:
        '&copy; OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
]

export default {
  components: {
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    RcOverlay,
    LMap,
    LTileLayer,
    LMarker,
    // eslint-disable-next-line vue/no-unused-components
    LTooltip,
    LIcon,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    BRow,
    BCol,
    BCard,
    BBadge,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      timeout: null,
      locations: [],
      highlightLocations: [],
      highlightMarkers: [],
      center: [51.9189046, 19.1343786],
      icon: '',
      opacity: 0.6,
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
      },
      zoom: 6,
      minZoom: 5,
      maxZoom: 18,
      zoomPosition: 'topleft',
      attributionPosition: 'bottomright',
      layersPosition: 'topright',
      attributionPrefix: 'eAmbulans',
      imperial: false,
      Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],
      tileProviders,
      bounds: null,
    }
  },
  computed: {
    markers() {
      const markers = []
      this.locations.forEach(location => {
        if (!empty(location.locationStatus)) {
          let tooltipText = `${location.vehicle.sideNumber} (${location.vehicle.registrationNumber})`
          if (!empty(location.team)) {
            tooltipText = `${location.team.teamName.name}: ${tooltipText}`
          }
          markers.push(
            {
              id: location.vehicle.id,
              position: { lat: location.locationStatus.latitude, lng: location.locationStatus.longitude },
              tooltip: tooltipText,
              draggable: false,
              visible: true,
              sideNumber: location.vehicle.sideNumber,
            },
          )
        }
      })
      return markers
    },
    me() {
      return this.$store.state.auth.activeUser
    },
    myTeam() {
      return this.$store.state.auth.userTeam
    },
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    init() {
      this.loading = true
      this.getLocations(true)
    },
    getLocations(first = false) {
      this.$store.dispatch('vehicle/fetchTrackingCurrentLocations')
        .then(res => {
          this.locations = res
          if (first) {
            this.$nextTick(() => {
              this.fitPolyline()
              this.loading = false
            })
          }
        })
        .finally(() => {
          this.timeout = setTimeout(() => {
            this.getLocations(false)
          }, 10000)
        })
    },
    fitPolyline() {
      const bounds = latLngBounds(this.markers.map(o => o.position))
      this.bounds = bounds
    },
    centerMapOnMarker(location) {
      const markerBounds = latLngBounds([{ lat: location.locationStatus.latitude, lng: location.locationStatus.longitude }])
      this.$refs.map.fitBounds(markerBounds)
    },
    highlightListItem(markerIds) {
      this.highlightLocations = markerIds
    },
    highlightMarker(markerIds) {
      this.highlightMarkers = markerIds
    },
    removeHighlight() {
      this.highlightLocations = []
      this.highlightMarkers = []
    },
  },
}
</script>

<style lang="scss" scoped>
.marker-icon {
  background: white;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  box-shadow: 0 0 2pt 3pt gray;
  > svg {
    margin: 6px;
  }
  &.highlight {
    box-shadow: 0 0 2pt 3pt red;
  }
  .side-number {
    text-align: center;
    padding-top: 6px;
    font-size: .9em;
  }
}
</style>
